<template>
  <div>
    <c-card title="상세" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="작성부서/이름/작성일" :value="request"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="사업장" :value="assessPlan.plantName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="평가기간" :value="assessPlan.assessmentStartDate + '~' + assessPlan.assessmentEndDate"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="평가년도" :value="assessPlan.assessmentYear"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="정기/수시" :value="assessPlan.ramAssessTypeName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="Matrix" :value="assessPlan.matrixName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-12 col-lg-6">
          <c-label-text title="평가명" :value="assessPlan.assessmentName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="주관" :value="assessPlan.regUserName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-12 col-lg-12" >
          <c-label-text :row="11" title="상세내용" :value="assessPlan.remark"></c-label-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-upload 
            :editable="false">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-card title="평가팀&관련자료" class="q-mt-sm" :noMarginPadding="true">
      <template slot="card-detail">
        <div class="col-12 q-mt-xs">
          <q-chip outline square
            v-for="(item, idx) in assessPlan.teams"
            :key="idx"
            color="primary"
            text-color="white"
            icon="person_outline"
            :label="setTeamLabel(item)"
            :title="setTeamLabel(item)"
            v-on:click="truncate = !truncate"
          >
            <q-tooltip>{{ item.label }}</q-tooltip>
          </q-chip>
        </div>
        <div class="col-12">
          <c-table
            class="q-mt-sm"
            ref="table3"
            title=""
            :isTitle="false"
            :columns="grid3.columns"
            :gridHeight="grid3.height"
            :data="assessPlan.relatedDocuments"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="false"
          >
          </c-table>
        </div>
      </template>
    </c-card>
    <c-table
      class="q-mt-sm"
      ref="table2"
      title="회의 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="assessPlan.conferences"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="false"
    >
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fm-plan-TL',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
  },
  data() {
    return {
      assessPlan: {
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        plantName: '',  // 사업장명
        ramTechniqueCd: null,  // 평가기법 코드_HAZOP/4m/JSA/CHECKLIST
        ramAssessTypeCd: null,  // 평가구분_최초/정기/수시
        ramAssessTypeName: null,  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: '',  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        regDt: '',
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        chgUserId: '',  // 수정자 ID
        teams: [], // 평가팀 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
        processes: [], // 대상공정 목록
      },
      grid: {
        columns: [
          {
            name: 'conferenceName',
            field: 'conferenceName',
            label: '회의명',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'conferenceDate',
            field: 'conferenceDate',
            label: '회의일',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'conferenceContents',
            field: 'conferenceContents',
            label: '회의내용',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'attendees',
            field: 'attendees',
            label: '참석자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '첨부파일',
            align: 'center',
            style: 'width:100px',
            type: 'attach',
            taskClassCd: 'RSA_CONFERENCE',
            keyText: 'ramAssessmentConferenceId',
            sortable: false,
          },
        ],
        data: [],
        height: '300px'
      },
      grid3: {
        columns: [
          {
            name: 'ramDocumentTypeName',
            field: 'ramDocumentTypeName',
            label: '분류',
            align: 'center',
            style: 'width:10%',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '자료명',
            align: 'left',
            style: 'width:75%',
            sortable: false,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '첨부파일',
            align: 'center',
            style: 'width:15%',
            type: 'attach',
            taskClassCd: 'RSA_RELATE_INFO',
            keyText: 'ramRelatedDocumentId',
            sortable: false,
          },
        ],
        height: '450px'
      },
      editable: true,
      detailUrl: '',
    };
  },
  computed: {
    request() {
      return this.assessPlan.regUserName + ' / ' + this.assessPlan.regDt
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.detailUrl = selectConfig.ram.assessPlan.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.assessPlan, _result.data);
        this.assessPlan.assessmentPeriod = this.assessPlan.assessmentStartDate && this.assessPlan.assessmentEndDate ?
          [this.assessPlan.assessmentStartDate, this.assessPlan.assessmentEndDate] : []

        this.$emit('setAssessmentName', this.assessPlan.assessmentName)
      },);
    },
    setTeamLabel(item) {
      let role = item.ramRoleTypeCd !== 'RRT0000010' ? item.ramRoleTypeName : item.ramRoleTypeName + '(' + item.etcRole + ')'
      return role + ' / ' + item.ramInsideOutsideName + ' / ' + item.userName + (item.deptName ? ' / ' + item.deptName : '')
    }
  }
};
</script>
